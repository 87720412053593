export default {
  home: "Utama",
  news: "Berita",
  ourPartners: "Mitra Kami",
  faq: "FAQ",
  address: "Alamat",
  investorToolkit: "Investor Toolkit",
  financialModelling: "Model Finansial",
  preparation: "Panduan Investasi",
  preOperational: "Pre-Operasi",
  operational: "Operasi",
  resources: "Informasi",
  gallery: "Galeri",
  about: "Tentang",
  aboutUs: "Tentang Kami",
  members: "Anggota Kami",
  referenceGroups: "Reference Groups",
  acknowledgement: "Ucapan Terima Kasih",
  contactUs: "Kontak Kami",
  links: "Tautan",
  search: "Cari konten",
  searchForAnyContent: "Cari konten apapun disini",
  searchResult: "Hasil pencarian",
  noResult: "Hasil tidak ditemukan",
  backToProvince: "Kembali ke pilih provinsi",
  goToInvestorToolkit: "Pergi ke Program Investor",
  goToAtricleList: "Pergi ke daftar Artikel",
  readMore: "Lebih lanjut",
  toolkitCompilations: "Kompilasi Toolkit",
  preOperational: {
    title: "Pendirian Badan Usaha",
    module: "Tipe Investasi",
    subModule: "Tipe Bisnis",
  },
  operational: {
    title: "Model Produksi Sapi Potong",
    module: "Model Bisnis",
    subModule: "Model Sub-bisnis",
  },
  resourcesTab: {
    stakeholders: "Stakeholder",
    regulatoryOverview: "Regulasi",
    newsAndArticles: "Berita dan Artikel",
    publications: "Publikasi",
    statistics: "Statistik",
  },
  visitOurWebsite: "Kunjungi situs kami",
  contact: "Kontak",
  ourSocialMedia: "Media Sosial",
  clickTheProvince: "Pilih provinsi untuk pelajari lebih lanjut",
  whyInvestIn: "Keunggulan terhadap investasi di industri sapi potong",
  keyPointsOfConsiderations: "Pertimbangan investasi pada industri sapi potong",
  category: "Kategori",
  sort: "Urutkan",
  yearAsc: "Tahun terlama",
  yearDesc: "Tahun terbaru",
  prev: "Kembali",
  next: "Lanjut",
  goTo: "Pelajari",
  download: "Unduh",
  articles: "Artikel",
  productivity: "Produktivitas",
  filterBy: "Filter berdasarkan",
  detailStakeholders: "Informasi Detail Stakeholder",

  GDRP: "PDRB",
  billionIDR: "Miliar Rupiah",
  billion: "miliar",
  IDR: "rupiah",
  USD: "USD",
  AUD: "AUD",
  thousand: "ribu",
  minimumWage: "UMR",
  population: "populasi",
  cattlePopulation: "populasi sapi",
  head: "ekor",
  national: "Nasional",
  regional: "Regional",
  regionalLivestock: "Peternakan Regional",
  regionPopulation: "Populasi Regional",
  nationPopulation: "Populasi National",
  provincialMinWage: "UMR Regional",
  nationalMinWage: "UMR Nasional",
  provincialCattlePopulation: "Populasi Sapi Potong Provinsi",
  nationalCattlePopulation: "Populasi Sapi Potong Nasional",
  existingBusinessModel: "Model Bisnis yang Tersedia",
  belowAreExistingBusinessModel: "Berikut model bisnis yang tersedia",
  numberOfFeedlot: "Jumlah Feedlot Komersial",
  numberOfBreedlot: "Jumlah Breedlot Komersial",
  numberofSmallholder: "Jumlah Smallholder",
  provinceSummary: "Ringkasan Provinsi​",
  investmentConsiderations: "Pertimbangan Investasi​",
  keyIndustries: "Industri utama",
  factsOnCattleIndustry: "Fakta terkait Industri Sapi Potong",
  feedlot: "Feedlot",
  breedlot: "Breedlot",
  cattleSmallholderFarmers: "Peternakan Rakyat",
  capitalCity: "Ibukota",
  city: "Kota",
  supplyValue: "Nilai Pasokan",
  meat: "Daging",
  tons: "Ton",
  heads: "Kepala",
  introduction: "Perkenalan",
  clickOnTheLegend: "Pilih legenda untuk menyembunyikan data",
  pleaseSelectAbove:
    "Silahkan pilih tipe di atas untuk melihat informasi yang dipilih",
  save: "Simpan",

  provinceSelection: "Pemilihan provinsi",
  provinceSelectionDesc:
    "Pilih provinsi untuk mengetahui lebih dalam informasi regional terkait bisnis sapi",
  generalInformation: "Informasi umum",
  generalInformationDesc: "Sorotan umum dari provinsi yang dipilih",
  detailInformation: "Informasi detil",
  detailInformationDesc: "Jelajahi lebih dalam provinsi yang dipilih",
  selectCurrency: "Pilih mata uang",
  generalOverview: "Gambaran Umum",
  goTo2: "Pergi ke",
  modelAssumption: "Asumsi Model",
  showAllData: "Tampilkan seluruh data",
  storiesFromField: "Cerita dari Lapangan",
  onlineInvestmentService: "Layanan Investasi Online",
  market: "Informasi Pasar",
  landUtilization: "Pemanfaatan Lahan",
  portInfrastructure: "Infrastruktur Pelabuhan",
  portForCattle: "Pelabuhan untuk Sapi",
  seaToll: "Rute Tol Laut",
  available: "Tersedia",
  unavailable: "Tidak tersedia",
  loadingUnloadingTime: "Waktu bongkar muat",
  loading: "Muat",
  unloading: "Bongkar",
  taxIncentive: "Regulasi dan Insentif",
  strongSector: "Sektor kuat",
  weakSector: "Sektor lemah",
  supply: "Memasok",
  import: "Impor",
  cattle: "Ternak",
  export: "Ekspor",
  marketSize: "Ukuran Pasar",
  year: "tahun",
  days: "Hari",
  livestockImport: "Import Ternak",
  cattleBreedBussiness: "Usaha Budidaya Sapi",
  cattleFatteningBussiness: "Usaha Penggemukan Sapi",
  livestockTraders: "Pedagang Ternak",
  abbatoir: "Rumah Potong Hewan",
  meatSeller: "Penjual Daging",
  consumer: "Konsumen",
  smallholders: "Petani Kecil",
  commercial: "Komersial",
  interDistrict: "Antar kabupaten",
  interProvince: "Antar provinsi",
  processingIndustries: "Industri Pengolahan",
  wholesale: "Grosir",
  retail: "Eceran",
  b2bCustomer: "Pelanggan B2B",
  b2cCustomer: "Pelanggan B2C",
  animalMarket: "Pasar Hewan",
  eidAdha: "Pelanggan Idul Adha",
  supportingStakeholder: "Stakeholder Pendukung",
  governmentBody: "Pemerintah",
  financialInstitution: "Lembaga Keuangan",
  higherEdInstitutions: "Institusi Pendidikan",
  veterinarians: "Veteriner",
  associations: "Asosiasi",
  feedMills: "Pabrik Pakan",
  supplyChain: "Rantai Pasok",
  supplyOrigin: "Pasok Asal",
  marketDestination: "Target Pasar",
  provincialMacro: "Data Makro Provinsi",
  provinceOverview: "Gambaran Provinsi",
  focusProvince: "Fokus Provinsi",
  realtimeUpdate: "Data Real-Time Harga Daging Sapi di Tiap Provinsi",
  realTimeUpdateDesc:
    "SIMPONI-Ternak adalah perangkat digital yang dikembangkan oleh Kementerian Pertanian RI untuk menjawab tingginya kebutuhan akan informasi pasar, yang mencakup harga real-time untuk beberapa komoditas peternakan di setiap provinsi. Untuk harga daging sapi real-time di setiap provinsi, silakan klik tombol di sebelah kanan.",
  supplyProvince: "Rute Pasokan ke Provinsi",
  provinceMarket: "Rute Provinsi ke Pasar",
  escasFeedlotTitle: "Penggemukan ESCAS",
  escasFeedlotSubTitle: "di Provinsi",
  escasAbattoirsTitle: "Rumah potong ESCAS",
  escasAbattoirsSubTitle: "di Provinsi",
  siskaPotential: "Potensi SISKA",
  title: {
    default: "Beef Cattle Investor Toolkit", //
    about: { 
      aboutUs: "Tentang Kami | Beef Cattle Investor Toolkit", //
      ourMember: "Team Member Kami | Invest in Cattle", //
      referenceGroups: "Grup Referensi | Invest in Cattle" //
    },
    financialModeling: "Model finansial | Invest in Cattle", //
    galery: "Galeri dan Insight | Invest in Cattle", //
    investorToolkit: "Investor Toolkit Peternakan Sapi | Invest in Cattle", //
    links: "Jaringan Kami | Invest in Cattle", //
    contact: "Hubungi kami | Invest in Cattle",
    acknowledgement: "Pengakuan | Invest in Cattle",
    preparation: {
      preOperational: "Panduan Investasi - Pendirian Badan Usaha", //
      operational: "Model Peoduksi Peternakan Sapi | Invest in Cattle" //
    },
    resources: {
      cattleStakeholders: "Stakeholder | Invest in Cattle",
      cattleStatistics: "Data Statistik | Invest in Cattle",
      newsAndArticles: "Berita & Info terkini | Invest in Cattle",
      publications: "Publikasi Kami| Invest in Cattle",
      regulatoryOverview: "Undang - Undang Investasi Peternakan Sapi | Invest in Cattle",
    },
    toolkitCompilations: "Jenis Toolkit | Invest in Cattle",
  },
  meta: {
    description: {
      default: "Invest in Cattle - Indonesia Australia Cattle & Red Meat Partnership, kolaborasi strategis mendorong perdagangan & mendukung penerapan sustainable pertanian.",
      about: { 
        aboutUs: "Invest in cattle perusahaan unggulan di Industri Peternakan, berkomitmen pada penerapan sustainable dan produksi daging sapi berkualitas",
        ourMember: "Temui para anggota Indonesia Australia Cattle Partnership profesional berdedikasi yang berkomitmen pada peternakan sapi yang sustainable dan profitable", //
        referenceGroups: "Join dengan Indonesia Australia Cattle Partnership's Reference Groups untuk mendapatkan akses ke ahli nya dalam problem-solving di industri peternakan" //
      },
      financialModeling: "Experience financial success dalam industri peternakan dengan modeling toolkit keuangan komprehensif Indonesia Australia Cattle Partnership and Toolkit Compilations",
      galery: "Temukan inovasi industri peternakan Indonesia Australia Cattle Partnership melalui kompilasi galeri gambar dan video ini",
      investorToolkit: "Maximize your investments in the cattle industry with the Indonesia Australia Cattle Partnership's Investor Toolkit - the ultimate resource for informed decision-making.", //
      links: "Perluas wawasan Anda dalam industri peternakan dengan jaringan professional dan pakar industri Indonesia Australia Cattle Partnership",
      contact: "Contact Us | Invest in Cattle", 
      acknowledgement: "Acknowledgement",
      preparation: {
        preOperational: "Siapkan bisnis peternakan sapi Anda dengan mudah menggunakan resources Indonesia Australia Cattle untuk persiapan awal pendirian badan usaha anda",
        operational: "Maksimalkan efisiensi dan profitabilitas peternakan Anda dengan model  Produksi Ternak Operasional  dari expert Indonesia Australia Cattle Partnership"
      },
      resources: {
        cattleStakeholders: "Experience the power of teamwork dan nilai - nilai bersama dalam industri peternakan melalui jaringan stakeholder dan mitra Indonesia Australia Cattle Partnership",
        cattleStatistics: "Akses data dan wawasan terbaru tentang produksi dan konsumsi ternak dengan statistik dan analisis komperehesif bersama Indonesia Australia Cattle Partnership",
        newsAndArticles: "Keep update tentang berita dan tren terbaru dalam industri peternakan dengan berita dan artikel dari Indonesia Australia Cattle Partnership",
        publications: "Stay informed & up-to-date tentang perkembangan terbaru dalam industri peternakan dengan publikasi & resources komprehensif dari Indonesia Australia Cattle Partnership",
        regulatoryOverview: "Regulasi lanskap yang kompleks dari industri peternakan dengan panduan ahli dan compilance resources Indonesia Australia Cattle Partnership",
      },
      toolkitCompilations: "Raih kesuksesan dalam industri peternakan dengan recources dan tools Indonesia Australia Cattle Partnership and Toolkit Compilations.",
    }
  },
};
