import create from "zustand";
var useStore = create(function (set) {
  return {
    locale: {},
    setLocale: function setLocale(loc) {
      return set(function () {
        return {
          locale: loc
        };
      });
    }
  };
});
export default useStore;