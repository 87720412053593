export default {
  home: "Home",
  news: "News",
  ourPartner: "Our Partner",
  faq: "FAQ",
  address: "Address",
  investorToolkit: "Investor Toolkit",
  financialModelling: "Financial Modeling",
  preOperational: "Business Entity Establishment",
  preparation: "Investment Guide",
  operational: "Operational",
  resources: "Resources",
  gallery: "Gallery",
  about: "About",
  aboutUs: "About Us",
  members: "Our Members",
  referenceGroups: "Reference Groups",
  acknowledgement: "Acknowledgement",
  contactUs: "Contact Us",
  links: "Links",
  search: "Search content",
  searchForAnyContent: "Search for any content",
  searchResult: "Search result",
  noResult: "No results found",
  backToProvince: "Back to province selection",
  goToInvestorToolkit: "Go to Investor Toolkit",
  goToAtricleList: "Go to Article List",
  readMore: "Read more",
  toolkitCompilations: "Toolkit Compilations",
  preOperational: {
    title: "Business Entity Establishment​",
    module: "Investment Type",
    subModule: "Business Type",
  },
  operational: {
    title: "Cattle Production Model​",
    module: "Business Model",
    subModule: "Sub-business Model",
  },
  resourcesTab: {
    stakeholders: "Stakeholders",
    regulatoryOverview: "Regulatory Overview",
    newsAndArticles: "News and Articles",
    publications: "Publications",
    statistics: "Statistics",
  },
  visitOurWebsite: "Visit our website",
  contact: "Contact",
  ourSocialMedia: "Our Social Media",
  clickTheProvince: "Click the province to learn more",
  whyInvestIn: "Predominant aspects for investing in cattle industry",
  keyPointsOfConsiderations:
    "Consideration points for investing in cattle industry",
  category: "Category",
  sort: "Sort by",
  yearAsc: "Year ascending",
  yearDesc: "Year descending",
  prev: "Prev",
  next: "Next",
  goTo: "Go to",
  download: "Download",
  articles: "Articles",
  productivity: "Productivity",
  filterBy: "Filter by",
  detailStakeholders: "Detail of Stakeholders",

  GDRP: "GDRP",
  billionIDR: "Billion IDR",
  billion: "billion",
  IDR: "IDR",
  USD: "USD",
  AUD: "AUD",
  thousand: "thousand",
  minimumWage: "minimum wage",
  population: "population",
  cattlePopulation: "cattle population",
  head: "head",
  national: "National",
  regional: "Regional",
  regionalLivestock: "Regional Livestock",
  regionPopulation: "Region Population",
  nationPopulation: "National Population",
  provincialMinWage: "Provincial Minimum Wage",
  nationalMinWage: "National Minimum Wage",
  provincialCattlePopulation: "Provincial Cattle Population",
  nationalCattlePopulation: "National Cattle Population",
  existingBusinessModel: "Existing business model",
  belowAreExistingBusinessModel: "Below are the existing business model​",
  numberOfFeedlot: "Number of Commercial Feedlot",
  numberOfBreedlot: "Number of Commercial Breedlot",
  numberofSmallholder: "Number of Smallholder",
  provinceSummary: "Province Summary​",
  investmentConsiderations: "Investment Considerations",
  keyIndustries: "Key industries",
  factsOnCattleIndustry: "Facts on Cattle Industry",
  feedlot: "Feedlot",
  breedlot: "Breedlot",
  cattleSmallholderFarmers: "Cattle Smallholder Farmers",
  capitalCity: "Capital City",
  city: "City",
  supplyValue: "Supply Value",
  meat: "Meat",
  tons: "Tons",
  heads: "Heads",
  introduction: "Introduction",
  clickOnTheLegend: "Click on the legend to hide selected data",
  pleaseSelectAbove:
    "Please select the types above to see the selected information",
  save: "Save",

  provinceSelection: "Province selection",
  provinceSelectionDesc:
    "Choose preferred location on a provincial level for further regional information related to cattle business",
  generalInformation: "General information",
  generalInformationDesc: "Read general highlights of the selected province​",
  detailInformation: "Detail information",
  detailInformationDesc:
    "Delve deep into the detailed provincial information related to cattle business",
  selectCurrency: "Select currency",
  generalOverview: "General Overview",
  goTo2: "Go to",
  modelAssumption: "Model Assumption",
  showAllData: "Show all data",
  storiesFromField: "Stories from the Field",
  market: "Market",
  landUtilization: "Land Utilization",
  portInfrastructure: "Port Infrastructure",
  portForCattle: "Port for Cattle",
  seaToll: "Sea Toll Route",
  available: "Available",
  unavailable: "Unavailable",
  loadingUnloadingTime: "Loading Unloading Time",
  loading: "Loading",
  unloading: "Unloading",
  taxIncentive: "Regulation and Incentives",
  strongSector: "Strong sectors",
  weakSector: "Weak sectors",
  supply: "Supply",
  import: "Import",
  cattle: "Cattle",
  export: "Export",
  marketSize: "Market Size",
  year: "year",
  days: "Days",
  livestockImport: "Livestock Import",
  cattleBreedBussiness: "Cattle Breeding Business",
  cattleFatteningBussiness: "Cattle Fattening Business",
  livestockTraders: "Livestock Traders",
  abbatoir: "Abbatoir",
  meatSeller: "Meat Seller",
  consumer: "Consumer",
  smallholders: "Smallholders",
  commercial: "Commercial",
  interDistrict: "Inter-district",
  interProvince: "Inter-province",
  processingIndustries: "Processing Industries",
  wholesale: "Wholesale",
  retail: "Retail",
  b2bCustomer: "B2B Customer",
  b2cCustomer: "B2C Customer",
  animalMarket: "Animal Market",
  eidAdha: "Eid Al-Adha Customer",
  supportingStakeholder: "Supporting Stakeholder",
  governmentBody: "Government Body",
  financialInstitution: "Financial Institutions",
  higherEdInstitutions: "Higher Education Institutions",
  veterinarians: "Veterinarians",
  associations: "Associations",
  feedMills: "Feed Mills",
  supplyChain: "Supply Chain",
  supplyOrigin: "Supply Origin",
  marketDestination: "Market Destination",
  provincialMacro: "Provincial Macro-data",
  provinceOverview: "Province Overview",
  focusProvince: "Focus Province​",
  realtimeUpdate: "Real-time update on Beef Prices in each Province​",
  realTimeUpdateDesc:
    "SIMPONI-Ternak is a digital tool developed by Indonesia’s Ministry of Agriculture in response to the high need for market information, covering real-time prices for multiple agricultural commodities in each province. For real-time beef prices in each province, please click the button on the right.",
  supplyProvince: "Supply to Province Route​",
  provinceMarket: "Province to Market Route​",
  escasFeedlotTitle: "ESCAS Feedlot in",
  escasFeedlotSubTitle: "The Province",
  escasAbattoirsTitle: "ESCAS Abattoir in",
  escasAbattoirsSubTitle: "The Province",
  siskaPotential: "SISKA's potential",
  title: {
    default: "Beef Cattle Investor Toolkit",
    about: { 
      aboutUs: "About Us | Beef Cattle Investor Toolkit",
      ourMember: "Our Members | Invest in Cattle",
      referenceGroups: "Reference Groups | Invest in Cattle"
    },
    financialModeling: "Financial Modeling | Invest in Cattle",
    galery: "Gallery and Insights | Invest in Cattle",
    investorToolkit: "Cattle Investor Toolkit | Invest in Cattle",
    acknowledgement: "Acknowledgement | Invest in Cattle",
    links: "Our Networks | Invest in Cattle",
    contact: "Contact Us | Invest in Cattle",
    preparation: {
      preOperational: "Business Entity Establishment - Investment Guide | Invest in Cattle",
      operational: "Cattle Production Model | Invest in Cattle"
    },
    resources: {
      cattleStakeholders: "Stakeholders | Invest in Cattle",
      cattleStatistics: "Cattle Statistics | Invest in Cattle",
      newsAndArticles: "News & Insights | Invest in Cattle",
      publications: "Our Publications | Invest in Cattle",
      regulatoryOverview: "Investment Regulation on Cattle | Invest in Cattle",
    },
    toolkitCompilations: "Toolkit Compilations | Invest in Cattle",
  },
  meta: {
    description: {
      default: "Discover the Indonesia Australia Cattle & Red Meat Partnership, a strategic collaboration boosting trade and supporting sustainable farming practices.", //
      about: { 
        aboutUs: "Indonesia Australia Cattle Partnership: A leading company in the livestock industry, committed to sustainable practices and quality beef production.", //
        ourMember: "Meet the members of the Indonesia Australia Cattle Partnership - a dedicated group of professionals committed to sustainable and profitable cattle farming practices.", //
        referenceGroups: "Join the Indonesia Australia Cattle Partnership's Reference Groups for access to expert insights and collaborative problem-solving in the livestock industry." //
      },
      financialModeling: "Experience financial success in the livestock industry with the Indonesia Australia Cattle Partnership and Toolkit Compilations' comprehensive financial modeling toolkit",
      galery: "Discover the beauty and innovation of the Indonesia Australia Cattle Partnership's livestock industry through their compelling gallery of images and videos.",
      investorToolkit: "Maximize your investments in the cattle industry with the Indonesia Australia Cattle Partnership's Investor Toolkit - the ultimate resource for informed decision-making.",
      acknowledgement: "Acknowledgement",
      links: "Expand your horizons in the livestock industry with the Indonesia Australia Cattle Partnership's vast network of industry professionals and experts.",
      contact: "Contact Us", //
      preparation: {
        preOperational: "Set up your cattle farming business with ease using the Indonesia Australia Cattle Partnership's pre-preparation resources for business entity establishment.",
        operational: "Maximize your cattle farming efficiency and profitability with the Indonesia Australia Cattle Partnership's expertly-designed Operational Cattle Production Model"
      },
      resources: {
        cattleStakeholders: "Experience the power of teamwork and shared values in the livestock industry through the Indonesia Australia Cattle Partnership's network of stakeholders and partners.",
        cattleStatistics: "Access the latest data and insights on cattle production and consumption with the Indonesia Australia Cattle Partnership's comprehensive statistics and analysis.",
        newsAndArticles: "Stay informed on the latest news and trends in the cattle industry with the Indonesia Australia Cattle Partnership's timely and insightful news and articles.",
        publications: "Stay informed and up-to-date on the latest developments in the cattle industry with the Indonesia Australia Cattle Partnership's comprehensive publications and resources.",
        regulatoryOverview: "Navigate the complex regulatory landscape of the cattle industry with the Indonesia Australia Cattle Partnership's expert guidance and compliance resources.",
      },
      toolkitCompilations: "Achieve success in the livestock industry with the Indonesia Australia Cattle Partnership and Toolkit Compilations' expert resources and tools.",
    }
  },
};
